import { AlertDialog, DialogContainer, SpectrumDialogContainerProps } from '@adobe/react-spectrum';
import { useCallback, useMemo, useState } from 'react';

const useOpenErrorDialog = () => {
  const [errorDialogIsOpen, setErrorDialogIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorTitle, setErrorTitle] = useState<string>();

  const handleErrorDialogDismiss: SpectrumDialogContainerProps['onDismiss'] = () => {
    setErrorDialogIsOpen(false);
  };

  const openErrorDialog = useCallback((errorTitle?: string, errorMessage?: string) => {
    setErrorTitle(errorTitle);
    setErrorMessage(errorMessage);
    setErrorDialogIsOpen(true);
  }, []);

  const errorDialog = useMemo(() => (
    <DialogContainer onDismiss={handleErrorDialogDismiss}>
      {errorDialogIsOpen && (
        <AlertDialog
          primaryActionLabel="Close"
          title={errorTitle ?? 'Error'}
          variant="error"
        >
          {errorMessage ?? 'An error has occurred. Please try again later.'}
        </AlertDialog>
      )}
    </DialogContainer>
  ), [errorDialogIsOpen, errorMessage, errorTitle]);

  return { errorDialog, openErrorDialog };
};

export default useOpenErrorDialog;
